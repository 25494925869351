<template>
  <div style="position: relative">
    <div id="monerisResponse"></div>

    <div id="payment-inst" class="columns tight">
      <p id="card">
        <a
          href="#"
          class="tooltip-right"
          :data-tooltip="$t('client.checkout.payment.card')"
          ><i class="fa fa-info-circle" aria-hidden="true"></i
        ></a>
      </p>
      <p id="expiry">
        <a
          href="#"
          class="tooltip-right"
          :data-tooltip="$t('client.checkout.payment.expiry')"
          ><i class="fa fa-info-circle" aria-hidden="true"></i
        ></a>
      </p>
      <p id="cvd">
        <a
          href="#"
          class="tooltip-right"
          :data-tooltip="$t('client.checkout.payment.cvd')"
          ><i class="fa fa-info-circle" aria-hidden="true"></i
        ></a>
      </p>
    </div>

    <div v-if="rendernow" id="payment-form" class="columns tight">
      <iframe
        id="monerisFrame"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        :src="ifrurl"
        :frameborder="frameborder"
        :width="framewidth"
        :height="frameheight"
      ></iframe>

      <!-- <br> -->
    </div>

    <button type="button" class="button" @click.prevent="doMonerisSubmit()">
      {{ $t('client.myAccount.vault.submitCard') }}
    </button>
  </div>
</template>

<script>

export default {
  mounted () {
    this.$nextTick(() => {
      this.rendernow = true
    })
  },
  props: [
    'user',
    'env',
    'frameborder',
    'framewidth',
    'frameheight',
    'host',
    'url',
    'profileId',
    'cssTextboxPan',
    'enableExp',
    'cssTextboxExp',
    'enableCvd',
    'cssTextboxCvd',
    'displayLabels',
    'cssInputLabel',
    'cssLabelPan',
    'cssLabelExp',
    'cssLabelCvd',
    'panLabel',
    'expLabel',
    'cvdLabel'
  ],
  data () {
    return {
      // url: 'https://esqa.moneris.com/HPPtoken/index.php'
      // test: {
      //   host: window.location.host,
      //   env: this.env,
      //   computedurl: this.ifrurl,
      //   moneris: this.gatewayHost,
      //   resturl: this.host
      // },
      rendernow: false,
      // host: this.gatewayHost
    }
  },
  computed: {
    ifrurl: function () {
      let _url = this.url + '?id=' + this.profileId
      if (this.cssTextboxPan) _url += '&css_textbox_pan=' + this.cssTextboxPan
      if (this.enableExp) _url += '&enable_exp=' + this.enableExp
      if (this.cssTextboxExp) _url += '&css_textbox_exp=' + this.cssTextboxExp
      if (this.enableCvd) _url += '&enable_cvd=' + this.enableCvd
      if (this.cssTextboxCvd) _url += '&css_textbox_cvd=' + this.cssTextboxCvd
      if (this.displayLabels) _url += '&display_labels=' + this.displayLabels
      if (this.cssInputLabel) _url += '&css_input_label=' + this.cssInputLabel
      if (this.cssLabelPan) _url += '&css_label_pan=' + this.cssLabelPan
      if (this.cssLabelExp) _url += '&css_label_exp=' + this.cssLabelExp
      if (this.cssLabelCvd) _url += '&css_label_cvd=' + this.cssLabelCvd
      if (this.panLabel) _url += '&pan_label=' + this.panLabel
      if (this.expLabel) _url += '&exp_label=' + this.expLabel
      if (this.cvdLabel) _url += '&cvd_label=' + this.cvdLabel
      _url += this.frameSize
      return encodeURI(_url)
    }
  },
  methods: {
    doMonerisSubmit: function () {
      this.$emit('tokenizerSubmit')
      console.info(' ::: called doMonerisSubmit in tokenizer')
      const monFrameRef = document.getElementById('monerisFrame').contentWindow
      monFrameRef.postMessage('tokenize', this.url)
      // restore to log -- but emit and catch on attrs
      // this.$dispatch('moneris.submit', this.ifrurl)
      // change link according to table above
      return false
    }
  }
}
</script>

<style>
#payment-inst {
  width: 1.5rem;
}
#payment-form {
  width: calc(100% - 1.5rem);
}

#payment-inst p {
  padding: 0 0 0 0;
  line-height: 375%;
  margin-bottom: 0;
}
/* Base styles for the element that has a tooltip */
[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: '';
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  width: 300px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -80px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
}

/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.tooltip-left:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: #000;
  border-left-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-left:hover:before,
.tooltip-left:hover:after,
.tooltip-left:focus:before,
.tooltip-left:focus:after {
  -webkit-transform: translateX(-12px);
  -moz-transform: translateX(-12px);
  transform: translateX(-12px);
}

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%;
}

.tooltip-bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #000;
  border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  transform: translateY(12px);
}

/* Right */
.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%;
}

.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px);
}

/* Move directional arrows down a bit for left/right tooltips */
.tooltip-left:before,
.tooltip-right:before {
  top: 3px;
}

/* Vertically center tooltip content for left/right tooltips */
.tooltip-left:after,
.tooltip-right:after {
  margin-left: 0;
  margin-bottom: -16px;
}
</style>
